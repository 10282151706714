import NiceSelect from "nice-select2/dist/js/nice-select2";
import "nice-select2/dist/css/nice-select2.css";

/**
 * Popup
 *
 * Class for handling popup component - /partials/ui/popup.php
 *
 */
export class Popup {
	/**
	 * constructor
	 *
	 * Sets the popup elements and initialise events.
	 *
	 *
	 * @param {string} target - Popup element.
	 *
	 */
	constructor( target ) {
		if ( typeof target === 'string' ) {
			this.popup = document.querySelector( target );
		} else if ( typeof target === 'object' ) {
			this.popup = target;
		}

		if ( this.popup === null ) {
			return;
		}

		this.popupClose = this.popup.querySelector( '.popup-close' );
		this.overlay = this.popup.querySelector( '.popup-overlay' );
		this.isActive = false;
		this.scrollLock = true;
		this.setCloseListener( this.popupClose );
		if ( this.overlay ) {
			this.setCloseListener( this.overlay );
		}

		this.beforeOpenDefaults = {
			duration: 0,
			opacity: 0,
		};

		this.onOpenDefaults = {
			duration: 0.6,
			yPercent: 0,
			opacity: 1,
			delay: 1,
		};

		this.onCloseDefaults = {
			duration: 0.6,
			opacity: 0,
		};

		const select = this.popup.getElementsByTagName( 'select' )[0];
		// Initialize the NiceSelect instance for the select element.
		if (!select.classList.contains('selectized')) {
			this.select = new NiceSelect( select, {
				searchable: false,
				placeholder: select.getAttribute( 'placeholder' ),
				multiple: false,
			} );
			select.classList.add('selectized')
		}
	}

	setCloseListener( closeBtn ) {
		closeBtn.addEventListener( 'click', ( e ) => {
			e.preventDefault();
			this.close();
		} );

		document.addEventListener( 'keydown', ( e ) => {
			if ( e.key === 'Escape' ) {
				this.close();
			}
		});
	}

	setOpenListener( openBtn ) {
		openBtn.addEventListener( 'click', ( e ) => {
			e.preventDefault();
			if ( this.isActive ) {
				this.close();
			} else {
				this.open();
			}
		} );
	}

	/**
	 * Opens the popup and adds necessary classes and animations
	 *
	 * @param {HTMLElement} popup - The popup element to open
	 */
	open() {
		if ( this.popup === null ) {
			return;
		}

		if ( this.scrollLock ) {
			document.body.classList.add( 'fixed', 'popup-open' );
		}

		// gsap.to( this.popup, {
			// ...this.beforeOpenDefaults,
		// } );

		this.popup.classList.remove( 'hidden' );

		// gsap.to( this.popup, {
		// 	...this.onOpenDefaults,
		// } );

		this.isActive = true;
	}

	/**
	 * Closes the popup and removes necessary classes and animations
	 *
	 * @param {HTMLElement} popup - The popup element to close
	 */
	close() {
		if ( this.popup === null ) {
			return;
		}

		document.body.classList.remove( 'fixed', 'popup-open' );

		// gsap.to( this.popup, {
		// 	...this.onCloseDefaults,
		// 	onComplete: () => {
				this.popup.classList.add( 'hidden' );
		// 	},
		// } );

		this.isActive = false;
	}
}