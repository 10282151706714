import { Popup } from "../class/Popup";
import { post } from '../../../src/js/components/_request';


export const popupController = function() {
	const triggerBtns	= document.querySelectorAll( '.js-open-popup' );
	if ( ! triggerBtns.length ){
		return;
	}
	const popups			= document.querySelectorAll( '.techin-popup' );

	triggerBtns.forEach( button => {
		button.addEventListener( 'click', function( e ) {
			e.preventDefault();
			let popupId = button.getAttribute('data-popup-id');
			let popup = document.querySelector( '.techin-popup[data-popup-id=' + popupId + ']')
			let targeted = new Popup( popup );
			targeted.open();
		} );
	});

	popups.forEach( popup => {
		if (!popup.classList.contains('mounted')) {
			const closeBtn		= popup.querySelector( '.popup-close' );
			const form			= popup.getElementsByTagName( 'form' )[0];
			const submitBtn		= popup.querySelector( '.popup_btn' );
			const successDiv	= popup.querySelector( '.sucess-message' );
			const errorClass	= 'form-element-error';
			const formElements = getAllFormElements( form );

			closeBtn.addEventListener( 'click', function( e ) {
				e.preventDefault();
				clearAllFields( formElements );
			} );

			document.addEventListener( 'keydown', ( e ) => {
				if ( e.key === 'Escape' ) {
					clearAllFields( formElements );
				}
			});

			submitBtn.addEventListener( 'click', function( e ) {
				e.preventDefault();
				var fields = gatherFormFields( form );
				let newsletterGroupString = form.getAttribute('data-newsletter-group');
				let newsletterGroupArr = newsletterGroupString.split(",");

				try {
					post( {
						action	: 'techin/ajax/popup',
						nonce	: fields[6].value,
						data	: JSON.stringify( gatherFormFields( form ) ),
						newsletter : JSON.stringify( newsletterGroupArr)
					} ).then( ( response ) => {
						if ( response.success ) {
							form.classList.add( 'hidden' );
							successDiv.classList.add( 'visible' );
							clearAllFields( formElements );
						} else {
							for ( const error in response.data ) {

								if ( error ) {
									var errorSpan = form.querySelector('[title="' + error + '"]');
									if ( errorSpan !== null ) {
										errorSpan.parentElement.classList.add( errorClass );
										const errorMsg = errorSpan.parentElement.querySelector( '.form-element-error-msg' );
										errorMsg.innerHTML = response.data[ error ];
									}
								}
							}

						}
					} );

				} catch ( error ) {
					console.error( error );
				}

			} );

			formElements.forEach( element => {
				switch ( element.type ) {
					case 'email':
					case 'text':
						element.addEventListener( 'input', function( e ) {
							this.parentElement.classList.remove( errorClass );
						} );
						break;
					case 'select-one':
						element.addEventListener( 'change', function( e ) {
							this.parentElement.classList.remove( errorClass );
						} );
						break;
				}
			});

			popup.classList.add('mounted')
		}
	});




	function getAllFormElements( element ) {
		return Array.from( element.elements ).filter( tag =>
		  ["select", "textarea", "input", "checkbox", "radio"]
			.includes( tag.tagName.toLowerCase() )
		);
	}

	function clearAllFields( formElements ){
		formElements.forEach( element => {
			if ( element.type !== 'hidden' ) {
				element.value = '';
				element.parentElement.classList.remove( 'has-value', 'form-element-error' );
			}
		});
	}

	function gatherFormFields( form ){
		var formElements = getAllFormElements( form );
		const arr = [];
		for ( let i=0; i < formElements.length; i++ ){
			let value;

			if ( formElements[i].type === 'checkbox' ) {
				value = formElements[i].checked;
			} else if ( formElements[i].type === 'radio' ) {
				if ( formElements[i].checked ) {
					value = formElements[i].value;
				} else {
					// Skip unselected radio buttons
					continue;
				}
			} else {
				value = formElements[i].value;
			}
		
			arr[i] = {
				title: formElements[i].title,
				type: formElements[i].type,
				name: formElements[i].name,
				required: formElements[i].required,
				attrs: formElements[i].getAttribute('data-attrs'),
				value: value,
			};
		}

		return arr;
	}
}